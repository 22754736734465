import React from 'react';
import logo from './../assets/svg/Logo.svg';
import { Link } from 'react-router-dom';
import downArrow from './../assets/svg/down-arrow.svg';

const linkStyle = () => {
    return {
        textDecoration: "none",
        color: "#ffffff"
    }
}

const Nav = () => {
    return (
        <nav className="header">
            <span className="logo-span"><Link to="https://www.sivioinstitute.org"><img src={logo} className="logo" alt="SIVIO logo" /></Link></span>
            <p className="nav-text otherTrackersLink" style={{minWidth: '200px'}}>The Price Tracker Home</p>
            <a href="https://openbudget.zimcitizenswatch.org" className="otherTrackersLink" target="_blank" style={{color:'#ffffff', textDecoration:'none', fontSize:20, textAlign: 'center',  minWidth: '150px'}}>Budget Tracker</a>
            <a href="https://zimcitizenswatch.org" className="otherTrackersLink" target="_blank" style={{color:'#ffffff', textDecoration:'none', textAlign: 'center', fontSize:20, minWidth: '150px'}}>Policy Tracker</a>
            
            <div class="dropdown other-trackers smScreen">
                <button class="dropbtn">Other Trackers <img src={downArrow} alt="down arrow" className="downarrow" /> </button>
                <div class="dropdown-content">
                    <a href="#">Policy Tracker</a>
                    <a href="#">Budget Tracker</a>
                </div>
            </div>
        </nav>
    );
}

export default Nav;