import React from 'react';
import CurrencyFormat from 'react-currency-format';

function AdvancedDescription(props) {
    const sbcStyles = {
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)'
    }
  return (
    <React.Fragment>
        <h4 className="m-0">Average Price by Brand 
        <span className="h4SubContent">
            {
            props.brand!==null ?
            props.brand.map(function(data, i) {
                return(<h5 key={i}>{data.item_name}
                <span>
                    <CurrencyFormat value={data.avg_price?data.avg_price:'0'} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'$'} renderText={value => <div>{value}</div>} />
                </span></h5>)
             }):
            <p>Not available</p>
            }
        </span>
        </h4>
        <h4 className="m-0">Average Price by Shop
        <span className="h4SubContent">
        {
            props.shop!==null ?
            props.shop.map(function(data, i) {
                return(<h5 key={i}>{data.shop}
                <span>
                    <CurrencyFormat value={data.avg_price?data.avg_price:"0"} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'$'} renderText={value => <div>{value}</div>} />
                </span></h5>)
             }):
            <p>Not available</p>
        }
        </span>
        </h4>
        <h4 className="m-0">Average Price by Brand, Shop, Residential Area and City
        <span className="h4SubContent">
        {
            props.sbc!==null ?
            props.sbc.map(function(data, i) {
                return(<h5 key={i} style={sbcStyles}>
                    <span>{data.city}</span>
                <span>{data.shop}</span>
                <span>
                    <CurrencyFormat value={data.avg_price?data.avg_price:"0"} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'$'} renderText={value => <div>{value}</div>} />
                </span></h5>)
             }):
            <p>Not available</p>
        }
        </span>
        </h4>
    </React.Fragment>
  );
}

export default AdvancedDescription;
