
import React from 'react'
import { render } from 'react-dom'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

const options = {
  chart: {
      type: 'line'
  },
  title: {
    text: 'Price Trends'
  },
  series: [{
      name: null,
      color: '#F15C27',
    data: []
  }],

  xAxis: {
        type: 'datetime',
        dateTimeLabelFormats: {
        day: '%d %b %Y'    //ex- 01 Jan 2016
        }
    },
yAxis: {
    title: {
        text: 'Prices'
    },
    labels: {
        formatter: function () {
            return '$'+this.value;
        }
    }
},
}

function TrendsDescription(props) {
    options.series[0].name = props.product_name;
    options.series[0].data = [];
    console.log(props);
    props.trends.map((data, i) => {
        if(data.created) {
            var parts =data.created.split("T", 1)[0].split('-');
            var mydate = new Date(parts[0], parts[1] - 1, parts[2]).getTime(); 
            options.series[0].data.push([mydate, Number(parseFloat(data.avg_price).toFixed(2))]);
        }
        
        //return true;
    }
    );
    console.log(options);
    return(<div>
        
    <HighchartsReact
        highcharts={Highcharts}
        options={options}
    />
    </div>);
}

export default TrendsDescription;
