import React from 'react';
import CurrencyFormat from 'react-currency-format';

function SimplifiedDescription(props) {
    console.log('the avrage price:',props.avg_price)
  return (
    <React.Fragment>
        <h4 className="m-0 tabSimpleh4">Average Price <span>
            {props.avg_price ?
            <CurrencyFormat value={props.avg_price?props.avg_price:'0'} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'$'} renderText={value => <div>{value}</div>} />
            :
            "Empty"
            }
            </span> </h4>
        <h4 className="m-0">Average Price by City 
        <span className="h4SubContent">
            {
            props.city !== null ?
            props.city.map(function(data, i) {
                return(<h5 key={i}>{data.city}<span>
                {
                    data.avg_price ?
                    <CurrencyFormat value={data.avg_price?data.avg_price:'0'} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'$'} renderText={value => <div>{value}</div>} />
                    :
                    "Empty"
                }
                    
                </span></h5>)
             }):
            <p>Not available</p>
            }

        </span>
        </h4>
        <h4 className="m-0">Average Price by Type of Residential Area
        <span className="h4SubContent"> 
            {
            props.surburbs!==null ?
            props.surburbs.map(function(data, i) {
                return(<h5 key={i}>{data.surburb}
                <span>
                    {
                        data.avg_price ?
                        <CurrencyFormat value={data.avg_price?data.avg_price:'0'} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'$'} renderText={value => <div>{value}</div>} />
                        :
                        "Empty"
                    }
                </span></h5>)
             }):
            <p>Not available</p>
            }
        </span>
        </h4>
    </React.Fragment>
  );
}

export default SimplifiedDescription;
