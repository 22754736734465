import React from 'react';
import SimplifiedDescription from './SimplifiedDescription';
import AdvancedDescription from './AdvancedDescription';
import TrendsDescription from './TrendsDescription';
import CurrencyFormat from 'react-currency-format';
import up_arrow from './../assets/svg/long-arrow-pointing-up.svg';
 

function ProductDescription(props) {
    const popupStyles = () => {
        return {
            position: 'fixed',
            right: '0',
            top:'100px',
            width: '0',
            maxWidth: '0',
            minWidth: '0',
            display: 'grid',
            gridTemplateColumns: '1fr',
            zIndex: '1500',
            boxShadow: '0px 10px 80px -10px rgba(44,54,92,0.2)'
        };
    };
    const headerStyles = () => {
        return {
            backgroundColor: 'var(--sivio-orange)',
            padding: '5px 15px',
            display: 'grid',
            gridTemplateColumns: '1fr',
            alignItems: 'center',
        };
    };
    const bodyStyles = () => {
        return {
            backgroundColor: 'var(--sivio-grey)',
            display: 'grid',
            gridTemplateColumns: '1fr',
            alignContent: 'start',
            padding: '20px',
            paddingBottom: '50px',
            overflowY: 'scroll',
            height: '60vh'
            
        };
    };
    const svgStyles = () => {
        return {
            justifySelf: 'end'
        };
    };

    const headerIntro = () => {
        return {
            display: 'grid',
            gridTemplateColumns: '1fr 1fr'
        }
    }

    const inflationStyles = () => {
        return {
            fontSize: '0.7em',
            display: 'grid',
            gridTemplateColumns: '1fr 1fr'
        }
    }

    return (
        <div className="popup" style={popupStyles()}>
            <div className="popupHeader" style={headerStyles()}>
                <div style={headerIntro()}>
                    <div style={inflationStyles()} className="inflationContainer">
                        <span style={{display: 'grid',gridTemplateColumns: '1fr'}}>
                            Price since last week 
                            <span style={{display: 'inline-flex'}}>
                                {
                                    props.weekly_change ?
                                    <img className={props.weekly_change>0? "upArrow": "upArrow rotateArrow"} alt='up arrow' src={up_arrow} /> :
                                    ""
                                }
                                <CurrencyFormat value={props.weekly_change?props.weekly_change:'0'} displayType={'text'} decimalScale={2} thousandSeparator={true} suffix={'%'} renderText={value => <div>{value}</div>} />
                            </span>
                        </span>
                        <span style={{display: 'grid',gridTemplateColumns: '1fr'}}>
                            Price since last month 
                            <span style={{display: 'inline-flex'}}>
                                {
                                    props.monthly_change ?
                                    <img className={props.monthly_change>0? "upArrow": "upArrow rotateArrow"} alt='up arrow' src={up_arrow} /> :
                                    ""
                                }
                                <CurrencyFormat value={props.monthly_change?props.monthly_change:'0'} displayType={'text'} decimalScale={2} thousandSeparator={true} suffix={'%'} renderText={value => <div>{value}</div>} />
                            </span>
                            
                        </span>
                    </div>

                    <button style={svgStyles()} onClick = {(e) => props.togglePopup(e, false)}>
                    <svg width="80"  height="80" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="50" cy="50.6899" r="34.8554" transform="rotate(45 50 50.6899)" stroke="#333F48"/>
                    <line x1="34.4666" y1="64.7286" x2="65.8421" y2="33.353" stroke="#333F48" strokeWidth="4"/>
                    <line x1="34.1579" y1="33.3531" x2="65.5334" y2="64.7286" stroke="#333F48" strokeWidth="4"/>
                    </svg>
                    </button>
                </div>
                <h2 className="m-0" style={{ marginTop: '0.3em !important' }}>{props.product} {props.quantity}</h2>

            </div>
            <div className="popupBody" style={bodyStyles()}>
                <div className="tabButtons" >
                    <button className="menuButton active" onClick={(e) =>props.loadTabContent(e, "simplified")}>Average Price</button>
                    <button className="menuButton"  onClick={(e) =>props.loadTabContent(e, 'advanced')}>Advanced Pricing</button>
                    <button className="menuButton"  onClick={(e) =>props.loadTabContent(e, 'trends')}>Trends</button>
                </div>
                <div className="tabContent">
                {props.content ?  
                <SimplifiedDescription avg_price={props.avg_price} surburbs={props.values.avgSurburb} city={props.values.avgCity}/>
                :
                    props.trends ?
                        <TrendsDescription trends={props.price_trends} product_name={props.product}/>
                        :
                        <AdvancedDescription brand={props.values.avgBrand} shop={props.values.avgShop} sbc={props.values.avgSBC}/>
                }  
                </div>
            </div>

        </div>
    );
}

export default ProductDescription;
