import React from 'react';
import './App.css';
import Nav from './Global/Nav';
import Home from './HomeComponents/Home';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ProductsProvider } from './Context/ProductsContext';

function App() {
  return (
    <ProductsProvider>
      <Router>
      <div className="container">
          <Nav />
            <Switch>
                <Route path="/" exact component={Home} />
            </Switch>
        </div>
      </Router>
    </ProductsProvider>
  );
}

export default App;
