import React, {useContext, useEffect, useState} from 'react';
import { ProductsContext } from '../Context/ProductsContext';
import axios from 'axios';

const url ="https://apipricetracker.zimcitizenswatch.org/api/";
//const url = "http://localhost:3000/api/";
const Product = (props) => {
    const [products, setProducts] = useContext(ProductsContext);
    const [query, setQuery] = useState(1);

    let itemClass = props.itemClass+" "+props.selected;

   useEffect(() => {
        function fetchData() {
            axios(
                `${url}categories/${query}`, {
                    headers: {
                    'Access-Control-Allow-Origin': '*',
                    }
                }
            ).then((result) => {
                setProducts({products: result['data']['products']});
            });
            
            setQuery(0);
        }
        if(query !== 0)
        fetchData();
    }, [query]);

    function loadProducts(e) {
        if( document.getElementsByClassName('item-1 selected')[0])
            document.getElementsByClassName('item-1 selected')[0].classList.remove('selected');
        if( document.getElementsByClassName('item-2 selected')[0])
            document.getElementsByClassName('item-2 selected')[0].classList.remove('selected');
        if( document.getElementsByClassName('item-3 selected')[0])
            document.getElementsByClassName('item-3 selected')[0].classList.remove('selected');
        if( document.getElementsByClassName('item-4 selected')[0])
            document.getElementsByClassName('item-4 selected')[0].classList.remove('selected');

        console.log(e.target.localName);
        console.log(e.target);

        if(e.target.localName === 'button')
        e.target.className = e.target.className.replace(e.target.className,e.target.className+' selected');
        else if(e.target.localName === 'div')
        e.target.parentElement.className = e.target.parentElement.className.replace(e.target.parentElement.className,e.target.parentElement.className+' selected');
        else
        e.target.parentElement.className = e.target.parentElement.className.replace(e.target.parentElement.className,e.target.parentElement.className+' selected');
        
        if(props.title === "Food & Beverage Prices")
            setQuery(prev => 1);     //setQuery(1);
        else if(props.title === "Non Food Prices")
            setQuery(prev => 2);    //setQuery(2);
        else if (props.title === "Other Prices")
            setQuery(prev => 3);
        else if (props.title === "Wholesale Prices")
            setQuery(prev => 4);
        else {}
        console.log(query);
        
        //console.log(newdata);
        //const newdata = [{class:'my class', period:'my period'}];
        //setProducts(oldData => [...oldData, newdata]);
        //console.log(products);
    }

    
    return (
        <button className={ itemClass } onClick={(e) =>loadProducts(e)}>
            <div className="item-box border-right" style={{pointerEvents: "none"}}>
                <span style={{pointerEvents: "none"}}><h4 className="m-0">{props.itemId}</h4> <svg width="39" height="5" viewBox="0 0 39 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.412354" width="37.7388" height="5" fill="white"/>
                </svg>
                </span>
                <h3 style={{pointerEvents: "none"}}>{props.title}</h3>
                <p style={{pointerEvents: "none"}}>{props.text} </p>
            </div>
        </button>
    );
}

export default Product;
