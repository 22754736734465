import React, {useContext} from 'react';
import { ProductsContext } from '../Context/ProductsContext';
import ListItem  from './ListItem';

const GroceriesList = (props) => {
    const [products, setProducts] = useContext(ProductsContext);
    console.log(products);

    return (
        <div className="left-desc-pane">
        <h1 className="left-desc-pane-h1">Groceries</h1>
        <div className="left-pane-item-holder">
        {
            products['products'].map((item, index) => (
                <ListItem key={item.id} id={ ('0' + (index+1)).slice(-2)} name={item.product_name} item_id={item.id} togglePopup ={ props.togglePopup } />
            ))
        }
        </div>
    </div>
    );

};

export default GroceriesList;
