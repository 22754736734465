import React, { useState, useEffect, Component} from 'react';
import '../Slider/custom.css';
import Product from './Product';
import GroceriesList from './GroceriesList';
import ProductDescription from './../ProductDescription/ProductDescription';
import axios from 'axios';
import { TweenLite, TimelineLite,TweenMax } from 'gsap/all';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import CSSPlugin from 'gsap/CSSPlugin';
import Loader from 'react-loader-spinner';


import Slideshow from "../Slider/Slideshow";
import slide1 from "../assets/slides/slide1.jpg";
import slide2 from "../assets/slides/slide2.jpg";
import slide3 from "../assets/slides/slide3.jpg";
import slide4 from "../assets/slides/slide4.jpg";
import slide5 from "../assets/slides/slide5.jpg";

//===========================================
// CREATE STYLES OBJECT
//===========================================
const s = {
    container: "screenW screenH dGray col",
    header: "flex1 fCenter fSize2",
    main: "flex8 white",
    footer: "flex1 fCenter"
};

//===========================================
// SLIDES DATA
//===========================================
const slides = [slide1, slide2, slide3, slide4, slide5];

const C = CSSPlugin;

const url ="https://apipricetracker.zimcitizenswatch.org/api/";
//const url ="http://localhost:3000/api/";

class Home extends Component {
    constructor (props) {
        super(props);
        this.state = { showPopup: false, product:{}, date:null, toggleDescriptionContent:true, avgCity:null, avgSurburb:null, avgBrand:null, avgShop:null, avgSBC:null, weekly_change:null, monthly_change:null, price_trends:null, loader:false};
        this.togglePopup = this.togglePopup.bind(this);
        this.fetchData = this.fetchData.bind(this);
        this.loadTabContent = this.loadTabContent.bind(this);
    }
    
    async fetchData(item_id) {
        //GET AVERAGE BY PRODUCT
        axios(
            `${url}products/summary/${item_id}`, {
                headers: {
                'Access-Control-Allow-Origin': '*',
                }
            }
        ).then((result) => {
            this.setState({product: result['data'][0]});
        }).catch((error) => {console.log(error)});
        
        //GET AVERAGE BY CITY
         axios(
            `${url}products/avgcity/${item_id}`, {
                headers: {
                'Access-Control-Allow-Origin': '*',
                }
            }
        ).then((result) => {
            this.setState({avgCity: result['data']});
        }).catch((error) => {console.log(error)});
        
        //GET AVERAGE BY RESIDENTIAL DENSITY AREA
        axios(
            `${url}products/avgsurburb/${item_id}`, {
                headers: {
                'Access-Control-Allow-Origin': '*',
                }
            }
        ).then((result)=> {
            this.setState({avgSurburb: result['data']});
        }).catch((error) => {console.log(error)});

        //GET AVERAGE BY BRAND
        axios(
            `${url}products/avgbrand/${item_id}`, {
                headers: {
                'Access-Control-Allow-Origin': '*',
                }
            }
        ).then((result) => {
            this.setState({avgBrand: result['data']});
        }).catch((error) => {console.log(error)});
        
         //GET AVERAGE BY SHOP
        axios(
            `${url}products/avgshop/${item_id}`, {
                headers: {
                'Access-Control-Allow-Origin': '*',
                }
            }
        ).then((result) => {
            this.setState({avgShop: result['data']});
        }).catch((error) => {console.log(error)});

        //GET AVERAGE BY SHOP, BRAND, CITY
        axios(
            `${url}products/avg-shop-brand-city/${item_id}`, {
                headers: {
                'Access-Control-Allow-Origin': '*',
                }
            }
        ).then((result) => {
            this.setState({avgSBC: result['data']});
        }).catch((error) => {console.log(error)});

        //GET WEEKLY INCREASE
        axios(
            `${url}products/increase-per-week/${item_id}`, {
                headers: {
                'Access-Control-Allow-Origin': '*',
                }
            }
        ).then((result) => {
            if(result)
            this.setState({ weekly_change: result['data'][0]['price_change']});
        }).catch((error) => {console.log(error)});
        
        //GET MONTHLY INCREASE
        axios(
            `${url}products/increase-per-month/${item_id}`, {
                headers: {
                'Access-Control-Allow-Origin': '*',
                }
            }
        ).then((result) => {
            if(result)
            this.setState({ monthly_change: result['data'][0]['price_change']});
        }).catch((error) => {console.log(error)});
        
        //GET PRICE TRENDS
        //GET MONTHLY INCREASE
        axios(
            `${url}products/price-trends/${item_id}`, {
                headers: {
                'Access-Control-Allow-Origin': '*',
                }
            }
        ).then((result) => {
            if(result)
            this.setState({ price_trends: result['data']});
        }).catch((error) => {console.log(error)});
        
        return true;
    }
    async togglePopup(e, item_id=false) { 
        this.setState({ loader: true});
        console.log("Toggle Popup Clicked");
        e.preventDefault();
        this.setState({product:{}, date:null, toggleDescriptionContent:true, showTrends:false, avgCity:null, avgSurburb:null, avgBrand:null, avgShop:null, avgSBC:null, weekly_change:null, monthly_change:null, price_trends: null});
        if(item_id !== false) {
            this.fetchData(item_id);
        }
              
        console.log(this.state.showPopup);
        if(!this.state.showPopup) {
            TweenMax.to('.popup', 1, {width: '85vw', maxWidth: '1200px', minWidth: '400px',});
        } else {
            TweenMax.to('.popup', 1, {width: '0', maxWidth: '0', minWidth: '0',});
        }
        return this.setState({  
            showPopup: !this.state.showPopup, loader: false
        });
    } 
    loadTabContent(e, tab) {
        e.preventDefault();
        document.getElementsByClassName('menuButton')[0].classList.remove('active');
        document.getElementsByClassName('menuButton')[1].classList.remove('active');
        document.getElementsByClassName('menuButton')[2].classList.remove('active');
        e.target.className = e.target.className.replace(e.target.className,e.target.className+' active');
        console.log(e);
        if(tab === 'simplified') {
            this.setState({ toggleDescriptionContent : true });
            this.setState({ showTrends : false });
        }
        else if(tab === 'advanced') {
            this.setState({ toggleDescriptionContent : false });
            this.setState({ showTrends : false });
        }
        else if(tab === 'trends') {
            this.setState({ toggleDescriptionContent : false });
            this.setState({ showTrends : true });
        } 
        else{}
    } 

    render() {
        return (
            <div className="wrapper">
            <ProductDescription product={this.state.product.product_name?this.state.product.product_name:""} date={this.state.date} avg_price={this.state.product.avg_price?this.state.product.avg_price:""} quantity={this.state.product.quantity?this.state.product.quantity:""} togglePopup = {this.togglePopup} loadTabContent={this.loadTabContent} content={this.state.toggleDescriptionContent} trends={this.state.showTrends} values={{avgSurburb:this.state.avgSurburb, avgCity:this.state.avgCity, avgBrand:this.state.avgBrand, avgShop:this.state.avgShop, avgSBC:this.state.avgSBC}} weekly_change={this.state.weekly_change} monthly_change={this.state.monthly_change} price_trends={this.state.price_trends}/> 
            <div className={this.state.loader ? "loaderContainer" : "hidden"} style={{width: '100%', height: '100%', position: 'fixed'}}>
                <Loader type="Rings" color="#f15c27" height={200} width={200} />
            </div>
            <div className="intro">
                <div className="introtext">
                    <h1>Zim Price Index</h1>
                    <p>The Zim Price Index tracks the value at which prices of goods are changing across all the major cities of Zimbabwe. It is an attempt to track inflation in realtime and also assist in broader analysis of cost of living.</p>
                </div>
            </div>
            <div className="pg-items">
                <div className="item-cont">
                <Product selected="selected" itemClass="item-1" itemId="1" title="Food &amp; Beverage Prices" text=""/>
                <Product selected="" itemClass="item-2" itemId="2" title="Non Food Prices" text=""/>
                <Product selected="" itemClass="item-3" itemId="3" title="Other Prices" text=""/>
                </div>
            </div>
            <div className="item-details">
                <GroceriesList togglePopup = {this.togglePopup} />
                <div className="right-desc-pane">
                <Slideshow slides={slides} />
                </div>
            </div>
            <div className="footer"></div>
            
        </div>
        );
    }

    
}
export default Home;
