import React from 'react';
import { Link } from 'react-router-dom';

const ListItem = (props) => {

    return(
        <button className="left-desc-item link-selected" onClick = {(e) => props.togglePopup(e, props.item_id)}><span className="step">{props.id}</span> <span className="title">{props.name} <span className="svg"></span></span></button>
    )
};

export default ListItem;